import React, {FC, useEffect, useRef, useState} from "react";
import SingleCommentForm from "./SingleCommentForm";
import SingleCommentLists from "./SingleCommentLists";
import useIntersectionObserver from "hooks/useIntersectionObserver";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentBtn";
import {ArrowUpIcon} from "@heroicons/react/24/solid";
import {useQuery} from "../../utils/queryUtils";
import {useGetArticleDetailQuery} from "../../store/api/article";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import Page404 from "../not-found";
import CustomTag from "../../components/blog/CustomTags/CustomTags";
import {CommentDto} from "../../data/types";
import {showSnackbar} from "../../store/reducers/snackbarSlice";
import {useAppDispatch, useAppSelector} from "../../store";
import {useAddCommentMutation} from "../../store/api/comment";

export interface SingleContentProps {}

const SingleContent: FC<SingleContentProps> = () => {
  const endedAnchorRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLButtonElement>(null);
  //
  const [isShowScrollToTop, setIsShowScrollToTop] = useState<boolean>(false);
  //

  const articleId = useQuery('aid');
  const { data , isLoading} = useGetArticleDetailQuery(articleId ?? '');

  const endedAnchorEntry = useIntersectionObserver(endedAnchorRef, {
    threshold: 0,
    root: null,
    rootMargin: "0%",
    freezeOnceVisible: false,
  });

  useEffect(() => {
    const handleProgressIndicator = () => {
      const entryContent = contentRef.current;
      const progressBarContent = progressRef.current;

      if (!entryContent || !progressBarContent) {
        return;
      }

      const totalEntryH = entryContent.offsetTop + entryContent.offsetHeight;
      let winScroll =
        document.body.scrollTop || document.documentElement.scrollTop;
      let scrolled = (winScroll / totalEntryH) * 100;

      progressBarContent.innerText = scrolled.toFixed(0) + "%";

      if (scrolled >= 100) {
        setIsShowScrollToTop(true);
      } else {
        setIsShowScrollToTop(false);
      }
    };

    const handleProgressIndicatorHeadeEvent = () => {
      window?.requestAnimationFrame(handleProgressIndicator);
    };
    handleProgressIndicator();
    window?.addEventListener("scroll", handleProgressIndicatorHeadeEvent);
    return () => {
      window?.removeEventListener("scroll", handleProgressIndicatorHeadeEvent);
    };
  }, []);

  const showLikeAndCommentSticky = true;
    // !endedAnchorEntry?.intersectionRatio &&
    // (endedAnchorEntry?.boundingClientRect.top || 0) > 0;

  /**
   * Post comments
   */
  const dispatch = useAppDispatch();
  const token = useAppSelector((state) => state.auth.token) ?? '';
  const [addComment] = useAddCommentMutation();
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleAddComment = async () => {
    if (textareaRef.current) {
      const content = textareaRef.current.value;
      const commentDto: CommentDto = {
        articleId: articleId ?? '',
        type: '0',
        rootId: '-1',
        toCommentId: '-1',
        toCommentUserId: '-1',
        content,
      };
      try {
        const addData = await addComment({
          commentDto,
          token,
        }).unwrap();

        if (addData.code === 200) {
          dispatch(showSnackbar({ message: 'Comment added successfully!', severity: 'success' }));
          textareaRef.current.value = ''; // Clear the input field
        }
      } catch (error) {
        console.error('Error adding comment:', error);
        // No need to handle error explicitly if middleware covers it
      }
    }
  };

  if (data?.code !== 200 && !isLoading) return <Page404 />;

  return (
    <div className="relative">
      <div className="nc-SingleContent space-y-10">
        {/* ENTRY CONTENT */}
        <div
          id="single-entry-content"
          className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
          ref={contentRef}
        >
          {/*<SingleContentDemo />*/}
          <ReactMarkdown
              remarkPlugins={[remarkGfm, remarkMath]}
              rehypePlugins={[rehypeKatex]}
          >
            {data?.data.content || ''}
          </ReactMarkdown>
        </div>

        {/* TAGS */}
        <div className="max-w-screen-md mx-auto flex flex-wrap">
          {data?.data.tags.map((item) => (
            <CustomTag hideCount key={item.id} tag={item} className="mr-2 mb-2" />
          ))}
        </div>

        {/* AUTHOR */}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {/*<div className="max-w-screen-md mx-auto ">*/}
        {/*  <SingleAuthor />*/}
        {/*</div>*/}

        {/* COMMENT FORM */}
        <div
          id="comments"
          className="scroll-mt-20 max-w-screen-md mx-auto pt-5"
        >
          <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
            Responses
          </h3>
          <SingleCommentForm
            textareaRef={textareaRef}
            onClickSubmit={handleAddComment}
          />
        </div>

        {/* COMMENTS LIST */}
        <div className="max-w-screen-md mx-auto">
          <SingleCommentLists />
          <div ref={endedAnchorRef}></div>
        </div>
      </div>
      <div
        className={`sticky mt-8 bottom-8 z-40 justify-center ${
          showLikeAndCommentSticky ? "flex" : "hidden"
        }`}
      >
        <div className="bg-white dark:bg-neutral-800 shadow-lg rounded-full ring-1 ring-offset-1 ring-neutral-900/5 p-1.5 flex items-center justify-center space-x-2 text-xs">
          {/*<PostCardLikeAction className="px-3 h-9 text-xs" />*/}
          {/*<div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>*/}
          <PostCardCommentBtn
            isATagOnSingle
            articleId={articleId ?? ''}
            className={` flex px-3 h-9 text-xs`}
          />
          <div className="border-l h-4 border-neutral-200 dark:border-neutral-700"></div>

          <button
            className={`w-9 h-9 items-center justify-center bg-neutral-50 dark:bg-neutral-800 hover:bg-neutral-100 rounded-full ${
              isShowScrollToTop ? "flex" : "hidden"
            }`}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            <ArrowUpIcon className="w-4 h-4" />
          </button>

          <button
            ref={progressRef}
            className={`w-9 h-9 items-center justify-center ${
              isShowScrollToTop ? "hidden" : "flex"
            }`}
            title="Go to top"
          >
            %
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleContent;
