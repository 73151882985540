import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BACKEND_BASE_URL} from "../../contains/constants";
import {ResponseResult, TagVo} from "../../data/types";

export const tagApi = createApi({
    reducerPath: 'tagApi',
    baseQuery: fetchBaseQuery({ baseUrl: BACKEND_BASE_URL }),

    endpoints: (build) => ({
        getTagList: build.query<ResponseResult<TagVo[]>, void>({
            query: () => 'tag/getTagList',
        }),
    }),
});

export const { useGetTagListQuery } = tagApi;
