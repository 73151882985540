import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BACKEND_BASE_URL} from "../../contains/constants";
import {ArticleDetailVo, ArticleListVo, HotArticleVo, PaginatedData, ResponseResult} from "../../data/types";
import {debounce} from "lodash";

const debouncedUpdateViewCount = debounce((updateViewCount, id) => {
    updateViewCount({ id });
}, 1000); // Adjust the debounce delay as needed


export const articleApi = createApi({
    reducerPath: 'articleApi',
    baseQuery: fetchBaseQuery({ baseUrl: BACKEND_BASE_URL }),

    endpoints: (build) => ({
        getArticleList: build.query<ResponseResult<PaginatedData<ArticleListVo>>, { categoryId: number; tagId:number; pageNum: number; pageSize: number }>({
            query: ({ categoryId, tagId,  pageNum, pageSize }) => `article/articleList?categoryId=${categoryId}&pageNum=${pageNum}&pageSize=${pageSize}&tagId=${tagId}`,
        }),
        getHotArticleList: build.query<ResponseResult<HotArticleVo[]>, void>({
            query: () => 'article/hotArticleList',
        }),
        updateViewCount: build.mutation<ResponseResult<null>, { id: string }>({
            query: ({ id }) => ({
                url: `article/updateViewCount/${id}`,
                method: 'PUT',
            }),
        }),
        getArticleDetail: build.query<ResponseResult<ArticleDetailVo>, string>({
            query: (id) => `article/${id}`,
        }),
    }),

});

export const {
    useGetArticleListQuery,
    useGetHotArticleListQuery,
    useUpdateViewCountMutation,
    useGetArticleDetailQuery
} = articleApi;

export { debouncedUpdateViewCount };
