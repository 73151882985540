import React, {FC} from "react";
import {HotArticleVo, PostDataType} from "data/types";
import NcImage from "components/NcImage/NcImage";
import HotArticleCard from "../HotArticleCard/HotArticleCard";

export interface SectionHero3Props {
    posts: PostDataType[];
    hotArticles: HotArticleVo[];
    className?: string;
}

const TopHero: FC<SectionHero3Props> = ({ posts, className = "" , hotArticles}) => {
    const renderMain = () => {
        const { featuredImage, title, desc, href } = posts[0];
        return (
            <div className="aspect-h-8 aspect-w-8 sm:aspect-w-10 lg:aspect-w-16 ">
                <NcImage
                    alt=""
                    containerClassName="absolute inset-0 rounded-[40px] overflow-hidden z-0"
                    src={featuredImage}
                    fill
                    sizes="(max-width: 1024px) 100vw, 1280px"
                />
                <span className="absolute inset-0 rounded-[40px] bg-black bg-opacity-50"></span>
                <div className="absolute inset-0 p-5 md:p-14 xl:p-20 2xl:p-28">
                    <div className="max-w-2xl">
                        <h2 className="text-xl sm:text-3xl lg:text-4xl font-semibold text-white">
                            <span className="line-clamp-2">{title}</span>
                        </h2>
                        <span className="block text-sm sm:text-base text-neutral-300 mt-3 sm:mt-5">
              <span className="line-clamp-2">{desc}</span>
            </span>
                        {/*<div className="mt-5 sm:mt-8">*/}
                        {/*  <ButtonSecondary href={href}>*/}
                        {/*    <span> Read more</span>*/}
                        {/*    <ArrowRightIcon className="w-6 h-6 ml-3" />*/}
                        {/*  </ButtonSecondary>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    };

    const renderSubPosts = () => {
        return (
            <div className="lg:px-14 xl:px-20 2xl:px-28 grid sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-8 transform mt-6 md:-mt-20">
                {hotArticles.slice(0, 3).map((hotArticle) => (
                    <HotArticleCard
                        className="bg-white dark:bg-neutral-800 shadow-2xl rounded-3xl"
                        key={hotArticle.id}
                        hotArticle={hotArticle}
                    />
                ))}
            </div>
        );
    };

    return (
        <div className={`nc-SectionHero3 ${className}`}>
            {posts.length && renderMain()}
            {posts.length > 1 && renderSubPosts()}
        </div>
    );
};

export default TopHero;