import {configureStore} from "@reduxjs/toolkit";
import {TypedUseSelectorHook, useDispatch, useSelector} from "react-redux";
import rootReducer from "./reducers";
import {articleApi} from "./api/article";
import {categoryApi} from "./api/category";
import {commentApi} from "./api/comment";
import {tagApi} from "./api/tag";
import apiMiddleware from "./middleware/apiMiddleware";
import {authApi} from "./api/auth";

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(
            articleApi.middleware,
            categoryApi.middleware,
            commentApi.middleware,
            authApi.middleware,
            tagApi.middleware,
            apiMiddleware,
        )
});

// Get Root state and dispatch type
type RootState = ReturnType<typeof store.getState>;
type AppDispatch = typeof store.dispatch;

// Create typed hooks for safely dispatching actions and selecting state in components
const useAppDispatch = () => useDispatch<AppDispatch>();
const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export { store, useAppDispatch, useAppSelector };