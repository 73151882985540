import React, {useEffect} from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Page} from "./types";
import MusicPlayer from "components/MusicPlayer/MusicPlayer";
import PageHome from "app/(home)/page";
import PageSingle from "app/(singles)/(default)/single/page";
import Page404 from "app/not-found";
import PageLogin from "app/(others)/login/page";
import PageSignUp from "app/(others)/signup/page";
import SiteHeader from "app/SiteHeader";
import DashboardEditProfile from "app/(others)/dashboard/edit-profile/page";
import {Provider} from 'react-redux';
import {store, useAppDispatch} from "../store";
import Snackbar from "components/Snackbar/Snackbar";
import GitHubCallback from "../components/blog/GitHubCallback/GitHubCallback";
import {REDIRECT_URI_ROUTE} from "../contains/constants";


export const pages: Page[] = [
  { path: "/", component: PageHome },
  { path: "/#", component: PageHome },
  // { path: "/home-2", component: PageHomeDemo2 },
  // { path: "/home-3", component: PageHomeDemo3 },
  // { path: "/home-4", component: PageHomeDemo4 },
  // { path: "/home-6", component: PageHomeDemo6 },

  // archives page -------------------------------------------------------
  // { path: "/archive/*", component: PageArchive },
  // { path: "/archive-2/*", component: PageArchiveAudio },
  // { path: "/archive-3/*", component: PageArchiveVideo },
  // { path: "/author/*", component: PageAuthor },

  // single page -------------------------------------------------------
  { path: "/DetailArticle", component: PageSingle },
  // { path: "/single-2/*", component: PageSingleTemplate2 },
  // { path: "/single-audio/*", component: PageSingleAudio },
  // { path: "/single-video/*", component: PageSingleVideo },
  // { path: "/single-gallery/*", component: PageSingleGallery },
  // { path: "/single-3/*", component: PageSingleTemplate3 },
  // { path: "/single-4/*", component: PageSingleTemplate4 },
  // { path: "/single-5/*", component: PageSingleTemplate2 },

  // search -------------------------------------------------------
  // { path: "/search", component: PageSearch },
  // { path: "/search-2", component: PageSearchV2 },

  // other pages -------------------------------------------------------
  // { path: "/about", component: PageAbout },
  // { path: "/contact", component: PageContact },
  { path: "/page404", component: Page404 },
  { path: "/login", component: PageLogin },
  { path: "/signup", component: PageSignUp },
  // { path: "/forgot-pass", component: PageForgotPass },
  // { path: "/subscription", component: PageSubcription },
  // { path: "/dashboard", component: DashboardSubmitPost },
  // { path: "/dashboard/posts", component: DashboardPosts },
  { path: "/dashboard/edit-profile", component: DashboardEditProfile },
  // { path: "/dashboard/subscription", component: DashboardSubcription },
  // { path: "/dashboard/billing-address", component: DashboardBilingAddress },
  // { path: "/dashboard/submit-post", component: DashboardSubmitPost },
];

const MyRoutes = () => {

  return (
      <Provider store={store}>
        <BrowserRouter>
          <SiteHeader />

          <Routes>
            {pages.map(({ component: Component, path }, index) => {
              return <Route key={index} element={<Component />} path={path} />;
            })}
            <Route path={REDIRECT_URI_ROUTE} element={<GitHubCallback />} />
            <Route path='*' element={<Page404 />} />
          </Routes>

          <Snackbar />
          {/*<Footer />*/}
          <MusicPlayer />
        </BrowserRouter>
      </Provider>
  );
};

export default MyRoutes;
