import React, {useState} from "react";
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Layout from "../layout";
import {useRegisterMutation} from "../../../store/api/auth";
import {useNavigate} from "react-router-dom";
import {useAppDispatch} from "../../../store";
import {showSnackbar} from "../../../store/reducers/snackbarSlice";

// const loginSocials = [
//   {
//     name: "Continue with Facebook",
//     href: "#",
//     icon: facebookSvg,
//   },
//   {
//     name: "Continue with Twitter",
//     href: "#",
//     icon: twitterSvg,
//   },
//   {
//     name: "Continue with Google",
//     href: "#",
//     icon: googleSvg,
//   },
// ];

const PageSignUp = () => {
  const [userName, setUserName] = useState('');
  const [nickName, setNickName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [register] = useRegisterMutation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!userName || !nickName || !email || !password) {
      setError('All fields are required.');
      return;
    }

    try {
      const result = await register({ userName, nickName, email, password }).unwrap();
      setError(''); // Clear error

      dispatch(showSnackbar({
        message: 'Registration successful!',
        severity: 'success',
      }));

      navigate('/login')
    } catch (err) {
      setError('Registration failed. Please try again.');
    }
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20">
        <Heading2>Sign up</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Welcome to my blog
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        {/*<div className="grid gap-3">*/}
        {/*  {loginSocials.map((item, index) => (*/}
        {/*    <a*/}
        {/*      key={index}*/}
        {/*      href={item.href}*/}
        {/*      className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"*/}
        {/*    >*/}
        {/*      <Image*/}
        {/*        className="flex-shrink-0"*/}
        {/*        src={item.icon}*/}
        {/*        alt={item.name}*/}
        {/*      />*/}
        {/*      <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">*/}
        {/*        {item.name}*/}
        {/*      </h3>*/}
        {/*    </a>*/}
        {/*  ))}*/}
        {/*</div>*/}
        {/* OR */}
        {/*<div className="relative text-center">*/}
        {/*  <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">*/}
        {/*    OR*/}
        {/*  </span>*/}
        {/*  <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>*/}
        {/*</div>*/}
        {/* FORM */}
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
          <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Username
        </span>
            <Input
                value={userName || ''}
                onChange={(e) => setUserName(e.target.value)}
                className="mt-1"
            />
          </label>
          <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Nickname
        </span>
            <Input
                value={nickName || ''}
                onChange={(e) => setNickName(e.target.value)}
                className="mt-1"
            />
          </label>
          <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Email address
        </span>
            <Input
                type="email"
                value={email || ''}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="example@example.com"
                className="mt-1"
            />
          </label>
          <label className="block">
        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
          Password
        </span>
            <Input
                type="password"
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1"
            />
          </label>
          {error && <p className="text-red-500">{error}</p>}
          <ButtonPrimary type="submit">Continue</ButtonPrimary>
        </form>

        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          Already have an account? {` `}
          <NcLink href="/login">Sign in</NcLink>
        </span>
      </div>
    </Layout>
  );
};

export default PageSignUp;
