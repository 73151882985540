import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface SnackbarState {
    open: boolean;
    message: string;
    severity: 'success' | 'info' | 'warning' | 'error';
}

const initialState: SnackbarState = {
    open: false,
    message: '',
    severity: 'info',
};

export const snackbarSlice = createSlice({
    name: 'snackbar',
    initialState,
    reducers: {
        showSnackbar: (state, action: PayloadAction<Omit<SnackbarState, 'open'>>) => {
            state.open = true;
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        hideSnackbar: (state) => {
            state.open = false;
            state.message = '';
            state.severity = 'info';
        },
    },
});

export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;