import {useEffect, useRef} from 'react';
import {useNavigate} from 'react-router-dom';
import {useGithubLoginMutation} from "../../../store/api/auth";
import {useAppDispatch} from "../../../store";
import {loginSuccess} from "../../../store/reducers/AuthSlice";

const GitHubCallback = () => {
    const navigate = useNavigate();
    const [githubLogin] = useGithubLoginMutation();
    const dispatch = useAppDispatch();
    const hasFetched = useRef(false);

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');

    useEffect(() => {
        if (code && !hasFetched.current) {
            hasFetched.current = true;
            githubLogin({ code })
                .unwrap()
                .then((result) => {
                    dispatch(loginSuccess({ token: result.data.token, userInfo: result.data.userInfo }));
                    navigate('/');
                })
                .catch((err) => {
                    // Handle error
                    // console.error(err);
                });
        }
    }, [code, githubLogin, dispatch, navigate]);

    return <div>Loading...</div>;
};

export default GitHubCallback;
