import {LoginResponse, ResponseResult, UserInfo} from "data/types";
import {BACKEND_BASE_URL} from "../../contains/constants";
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: fetchBaseQuery({ baseUrl: BACKEND_BASE_URL }),
    endpoints: (build) => ({
        usernameLogin: build.mutation<ResponseResult<LoginResponse>, { userName: string; password: string }>({
            query: ({ userName, password }) => ({
                url: 'login/username',
                method: 'POST',
                body: { userName, password },
            }),
        }),
        githubLogin: build.mutation<ResponseResult<LoginResponse>, { code: string }>({
            query: ({ code }) => ({
                url: `login/github?code=${code}`,
                method: 'POST',
                body: {},
            }),
        }),
        logout: build.mutation<ResponseResult<null>, { token: string }>({
            query: ({ token }) => ({
                url: 'logout',
                method: 'POST',
                headers: {
                    token: `${token}`,
                },
            }),
        }),
        register: build.mutation<ResponseResult<null>, { email: string; nickName: string; password: string; userName: string }>({
            query: (user) => ({
                url: 'user/register',
                method: 'POST',
                body: user,
            }),
        }),
        uploadImg: build.mutation<ResponseResult<string>, { formData: FormData; token: string }>({
            query: ({ formData, token }) => ({
                url: 'upload',
                method: 'POST',
                body: formData,
                headers: {
                    token: `${token}`,
                },
            }),
        }),
        userInfo: build.query<ResponseResult<UserInfo>, { token: string }>({
            query: ({ token }) => ({
                url: 'user/userInfo',
                method: 'GET',
                headers: {
                    token: `${token}`,
                },
            }),
        }),
        updateUserInfoApi: build.mutation<ResponseResult<string>, { avatar: string; nickName: string; token: string }>({
            query: ({ avatar, nickName, token }) => ({
                url: 'user/userInfo',
                method: 'PUT',
                body: { avatar, nickName },
                headers: {
                    token: `${token}`,
                },
            }),
        }),
    }),
});

export const {
    useUsernameLoginMutation,
    useGithubLoginMutation,
    useLogoutMutation,
    useRegisterMutation,
    useUploadImgMutation,
    useUserInfoQuery,
    useUpdateUserInfoApiMutation
} = authApi;