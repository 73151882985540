import React from "react";
import {DEMO_POSTS,} from "data/posts";
import SectionLatestPosts from "components/Sections/SectionLatestPosts";
import {useGetHotArticleListQuery} from "../../store/api/article";
import TopHero from "../../components/TopHero/TopHero";
import {Helmet} from "react-helmet";
import SEO from "../../data/SEO";

//
const MAGAZINE1_POSTS = DEMO_POSTS.filter((_, i) => i >= 8 && i < 16);

const currentSEO = SEO.find((item) => item.page === "home");

const PageHome = () => {
    const { data } = useGetHotArticleListQuery();

    return (
        <React.Fragment>
            <Helmet>
                <meta name="description" content={currentSEO?.description} />
                <meta
                    name="keywords"
                    content={currentSEO?.keywords.join(", ")}
                />
            </Helmet>

            <div className="nc-PageHome relative">
              <div className="container relative">
                <TopHero className="pb-16 lg:pb-28" posts={MAGAZINE1_POSTS} hotArticles={data?.data || []}/>

                <SectionLatestPosts className="pb-16 lg:pb-28" />
              </div>
            </div>
        </React.Fragment>
  );
};

export default PageHome;
