import React, {FC} from "react";
import Heading from "components/Heading/Heading";
import WidgetTags from "components/WidgetTags/WidgetTags";
import {ArticleListVo, PostDataType} from "data/types";
import Pagination from "components/Pagination/Pagination";
import {useGetArticleListQuery} from "../../store/api/article";
import ArticleListCard from "../ArticleListCard/ArticleListCard";
import CustomWidgetCategory from "../blog/WidgetCategory/CustomWidgetCategory";
import {useQuery} from "../../utils/queryUtils";
import {ARTICLE_PER_PAGE} from "../../contains/constants";

// THIS IS DEMO FOR MAIN DEMO
// OTHER DEMO WILL PASS PROPS
// const postsDemo: PostDataType[] = DEMO_POSTS.filter((_, i) => i > 7 && i < 15);
//
export interface SectionLatestPostsProps {
  posts?: PostDataType[];
  gridClass?: string;
  className?: string;
  heading?: string;
  postCardName?:
    | "card3"
    | "card4"
    | "card7"
    | "card9"
    | "card10"
    | "card11"
    | "card14";
}

const SectionLatestPosts: FC<SectionLatestPostsProps> = ({
  posts ,
  postCardName = "card3",
  heading = "Explore My Tech Space 🚀",
  gridClass = "",
  className = "",
}) => {
  const categoryId = parseInt(useQuery('cid') || '0', 10);
  const tagId = parseInt(useQuery('tid') || '0',10);
  const pageNum = parseInt(useQuery('page') || '1',10 );

  const { data } = useGetArticleListQuery({ categoryId, tagId, pageNum, pageSize: ARTICLE_PER_PAGE });

  const renderCard = (post: ArticleListVo, index: number) => {
    switch (postCardName) {
      case "card3":
        return (
          // <Card3 key={index} className="p-3 sm:p-5 2xl:p-6 " post={post} />
          <ArticleListCard key={index} className="py-3" post={post} />
        );
      // case "card4":
      //   return <Card4 key={index} post={post} />;
      // case "card7":
      //   return <Card7 key={index} post={post} ratio="aspect-w-5 aspect-h-5" />;
      // case "card9":
      //   return <Card9 key={index} post={post} />;
      // case "card10":
      //   return <Card10 key={index} post={post} />;
      // case "card11":
      //   return <Card11 key={index} post={post} />;
      // case "card14":
      //   return <Card14 key={index} post={post} />;
      default:
        return null;
    }
  };

  return (
    <div className={`nc-SectionLatestPosts relative ${className}`}>
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 xl:pr-14">
          <Heading>{heading}</Heading>
          <div className={`grid gap-6 md:gap-8 ${gridClass}`}>
            {data?.data.rows?.map((post, index) => renderCard(post, index))}
          </div>
          <div className="flex flex-col mt-12 md:mt-20 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between sm:items-center">
            <Pagination
            totalPages={data?.data.total}
            currentPage={pageNum}
            />
            {/*<ButtonPrimary>Show me more</ButtonPrimary>*/}
          </div>
        </div>
        <div className="w-full space-y-7 mt-24 lg:mt-0 lg:w-2/5 lg:pl-10 xl:pl-0 xl:w-1/3 ">
          {/*<WidgetPosts />*/}
          <WidgetTags />
          <CustomWidgetCategory />
          {/*<WidgetAuthors />*/}
        </div>
      </div>
    </div>
  );
};

export default SectionLatestPosts;
