import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BACKEND_BASE_URL} from "../../contains/constants";
import {CommentDto, CommentVo, PaginatedData, ResponseResult} from "../../data/types";

export const commentApi = createApi({
    reducerPath: 'commentApi',
    baseQuery: fetchBaseQuery({ baseUrl: BACKEND_BASE_URL }),

    tagTypes: ['comments'],
    endpoints: (build) => ({
        getCommentList: build.query<ResponseResult<PaginatedData<CommentVo>>, { articleId: number; pageNum: number; pageSize: number }>({
            query: ({ articleId, pageNum, pageSize }) => `comment/commentList?articleId=${articleId}&pageNum=${pageNum}&pageSize=${pageSize}`,
            providesTags:['comments']
        }),
        getCommentCount: build.query<ResponseResult<string>, { articleId: number }>({
            query: ({ articleId }) => `comment/commentCount?articleId=${articleId}`,
            providesTags:['comments']
        }),
        addComment: build.mutation<ResponseResult<void>, { commentDto: CommentDto; token: string }>({
            query: ({ token, commentDto }) => ({
                url: 'comment',
                method: 'POST',
                body: commentDto,
                headers: {
                    token: `${token}`,
                },
            }),
            invalidatesTags: ['comments']
        }),
    }),
});

export const { useGetCommentListQuery, useGetCommentCountQuery, useAddCommentMutation } = commentApi;