import React, {FC} from "react";
import Avatar from "components/Avatar/Avatar";
import {PostDataType} from "data/types";
import {DEMO_POSTS} from "data/posts";
import Link from "components/Link";
import convertNumbThousand from "../../utils/convertNumbThousand";

const metaDemo: PostMeta2Props["meta"] = DEMO_POSTS[0];

export interface PostMeta2Props {
  className?: string;
  meta?: Pick<PostDataType, "date" | "author" | "categories" | "readingTime">;
  hiddenCategories?: boolean;
  size?: "large" | "normal";
  avatarRounded?: string;
  date?: string;
  viewCount?: number;
}

const PostMeta2: FC<PostMeta2Props> = ({
  className = "leading-none",
  meta = metaDemo,
  hiddenCategories = false,
  size = "normal",
  avatarRounded,
   date,
   viewCount
}) => {
  const { author, categories } = meta;
  return (
    <div
      className={`nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 ${
        size === "normal" ? "text-xs" : "text-sm"
      } ${className}`}
    >
        <Avatar
          radius={avatarRounded}
          sizeClass={
            size === "normal"
              ? "h-6 w-6 text-sm"
              : "h-10 w-10 sm:h-11 sm:w-11 text-xl"
          }
          imgUrl="https://shiguo-blog.s3.ca-central-1.amazonaws.com/2024/admin/IMG_0302.jpg"
          userName="Shiguo Feng"
        />
      <div className="ml-3">
        <div className="flex items-center">
          <div className="block font-semibold">
            Shiguo Feng
          </div>

          {!hiddenCategories && (
            <>
              <span className="mx-2 font-semibold">·</span>
              <div className="ml-0">
                <span className="text-xs">🏷 </span>
                {categories.map((cat, index) => (
                  <Link key={cat.id} href={cat.href} className="font-semibold">
                    {cat.name}
                    {index < categories.length - 1 && <span>, </span>}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="text-xs mt-[6px]">
          <span className="text-neutral-700 dark:text-neutral-300">{date}</span>
          <span className="mx-2 font-semibold">·</span>
          <span className="text-neutral-700 dark:text-neutral-300">
            {convertNumbThousand(viewCount ?? 0)} Viewed
          </span>
        </div>
      </div>
    </div>
  );
};

export default PostMeta2;
