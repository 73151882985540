import React, {useState} from "react";
import githubSvg from "images/github.svg"
import Input from "components/Input/Input";
import ButtonPrimary from "components/Button/ButtonPrimary";
import NcLink from "components/NcLink/NcLink";
import Heading2 from "components/Heading/Heading2";
import Image from "components/Image";
import Layout from "../layout";
import {useAppDispatch} from "../../../store";
import {useUsernameLoginMutation} from "../../../store/api/auth";
import {loginSuccess} from "../../../store/reducers/AuthSlice";
import {useNavigate} from "react-router-dom";
import {GITHUB_CLIENTID, REDIRECT_URI} from "../../../contains/constants";

const loginSocials = [
  // {
  //   name: "Continue with Facebook",
  //   href: "#",
  //   icon: facebookSvg,
  // },
  // {
  //   name: "Continue with Twitter",
  //   href: "#",
  //   icon: twitterSvg,
  // },
    //TODO: 可以做这个
  // {
  //   name: "Continue with Google",
  //   href: "#",
  //   icon: googleSvg,
  // },
  {
    name: "Continue with Github",
    href: `https://github.com/login/oauth/authorize?client_id=${GITHUB_CLIENTID}&redirect_uri=${REDIRECT_URI}`,
    icon: githubSvg,
  },
];

const PageLogin = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [usernameLogin, { isLoading }] = useUsernameLoginMutation();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!username || !password) {
      setError('Username and password are required.');
      return;
    }

    try {
      const result = await usernameLogin({ userName: username, password }).unwrap();
      dispatch(loginSuccess({ token: result.data.token, userInfo: result.data.userInfo }));
      setError(''); // Clear error
      navigate('/'); // Redirect to homepage
    } catch (err) {
      // Do Nothing, handled in backend and middleware
    }
  };

  return (
    <Layout>
      <header className="text-center max-w-2xl mx-auto - mb-14 sm:mb-16 lg:mb-20 ">
        <Heading2>Login</Heading2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Welcome to our blog magazine Community
        </span>
      </header>

      <div className="max-w-md mx-auto space-y-6">
        <div className="grid gap-3">
          {loginSocials.map((item, index) => (
            <a
              key={index}
              href={item.href}
              className="flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
            >
              <Image
                className="flex-shrink-0"
                src={item.icon}
                alt={item.name}
              />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                {item.name}
              </h3>
            </a>
          ))}
        </div>
        {/* OR */}
        <div className="relative text-center">
          <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
            OR
          </span>
          <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
        </div>
        {/* FORM */}
        {/*<form className="grid grid-cols-1 gap-6" action="#" method="post">*/}
        {/*  <label className="block">*/}
        {/*    <span className="text-neutral-800 dark:text-neutral-200">*/}
        {/*     Username*/}
        {/*    </span>*/}
        {/*    <Input*/}
        {/*      type="email"*/}
        {/*      placeholder="John Doe"*/}
        {/*      className="mt-1"*/}
        {/*    />*/}
        {/*  </label>*/}
        {/*  <label className="block">*/}
        {/*    <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">*/}
        {/*      Password*/}
        {/*      /!*<NcLink href="/forgot-pass" className="text-sm underline">*!/*/}
        {/*      /!*  Forgot password?*!/*/}
        {/*      /!*</NcLink>*!/*/}
        {/*    </span>*/}
        {/*    <Input type="password" className="mt-1" />*/}
        {/*  </label>*/}
        {/*  <ButtonPrimary type="submit">Continue</ButtonPrimary>*/}
        {/*</form>*/}
        <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
          <label className="block">
        <span className="text-neutral-800 dark:text-neutral-200">
          Username
        </span>
            <Input
                type="text"
                value={username || ''}
                onChange={(e) => setUsername(e.target.value)}
                className="mt-1"
            />
          </label>
          <label className="block">
        <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
          Password
        </span>
            <Input
                type="password"
                value={password || ''}
                onChange={(e) => setPassword(e.target.value)}
                className="mt-1"
            />
          </label>
          {error && <p className="text-red-500">{error}</p>}
          <ButtonPrimary type="submit" disabled={isLoading}>
            {isLoading ? 'Logging in...' : 'Continue'}
          </ButtonPrimary>
        </form>
        {/* ==== */}
        <span className="block text-center text-neutral-700 dark:text-neutral-300">
          New user? {` `}
          <NcLink href="/signup">Create an account</NcLink>
        </span>
      </div>
    </Layout>
  );
};

export default PageLogin;
