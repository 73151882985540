const avatarColors = [
  "#ffdd00",
  "#fbb034",
  "#ff4c4c",
  "#c1d82f",
  "#f48924",
  "#7ac143",
  "#30c39e",
  "#06BCAE",
  "#0695BC",
  "#037ef3",
  "#146eb4",
  "#8e43e7",
  "#ea1d5d",
  "#fc636b",
  "#ff6319",
  "#e01f3d",
  "#a0ac48",
  "#00d1b2",
  "#472f92",
  "#388ed1",
  "#a6192e",
  "#4a8594",
  "#7B9FAB",
  "#1393BD",
  "#5E13BD",
  "#E208A7",
];

export { avatarColors };

// export const BACKEND_BASE_URL = "http://localhost:7777/";
export const BACKEND_BASE_URL = "https://blog.api.shiguofeng.com/";

// export const REDIRECT_URI = "http://localhost:3000/callback";
export const REDIRECT_URI = "https://blog.shiguofeng.com/callback";
export const REDIRECT_URI_ROUTE = '/callback';
export const GITHUB_CLIENTID = 'Ov23lixHHb3CiG4kPc4p';
export const DETAIL_ARTICLE_BASE_URL = 'DetailArticle?aid=';
export const ARTICLE_PER_PAGE = 5;
export const BADGE_COLORS: string[] = ["indigo", "red", "yellow", "pink", "blue", "gray", "purple"];
