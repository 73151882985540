import React, {FC} from "react";
import NcImage from "components/NcImage/NcImage";
import {ArticleListVo} from "data/types";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikeAndComment";
import Link from "components/Link";
import {DETAIL_ARTICLE_BASE_URL} from "../../contains/constants";
import CategoryBadgeDisplay from "../CategoryBadgeDisplay/CategoryBadgeList";
import ArticleListDateViewed from "../blog/ArticleListDateViewed/ArticleListDateViewed";

export interface ArticleListCardProps {
    className?: string;
    post: ArticleListVo;
}

const ArticleListCard: FC<ArticleListCardProps> = ({ className = "h-full", post }) => {
    const {
        title,
        id,
        thumbnail,
        summary,
        // categories,
        categoryName,
        categoryId,
        createTime,
        viewCount
    } = post;

    const href = `${DETAIL_ARTICLE_BASE_URL}${id}`

    return (
        <div
            className={`nc-Card3 relative flex flex-row items-center group ${className}`}
        >
            <div className="flex flex-col flex-grow">
                <div className="space-y-3.5">
                    <CategoryBadgeDisplay categoryName={categoryName} categoryId={categoryId} />
                    <Link href={href} className="block">
                        <h2
                            className={`nc-card-title block font-medium sm:font-semibold text-neutral-900 dark:text-neutral-100 text-sm sm:text-base xl:text-lg`}
                        >
              <span className="line-clamp-2" title={title}>
                {title}
              </span>
                        </h2>
                        <div className="hidden sm:block sm:mt-2">
              <span className="text-neutral-500 dark:text-neutral-400 text-sm line-clamp-2">
                {summary}
              </span>
                        </div>
                    </Link>

                    <ArticleListDateViewed createDate={createTime} viewCount={viewCount}/>
                </div>
                <div className="mt-5 flex items-center flex-wrap justify-between">
                    <PostCardLikeAndComment articleId={id}/>
                </div>
            </div>

            <div
                className={`block flex-shrink-0 w-24 sm:w-36 md:w-44 xl:w-56 ml-3 sm:ml-6 rounded-3xl overflow-hidden z-0 mb-5 sm:mb-0`}
            >
                <Link
                    href={href}
                    className="block w-full h-0 aspect-h-1 aspect-w-1 relative"
                >
                    <NcImage
                        containerClassName="absolute inset-0"
                        src={thumbnail}
                        fill
                        alt={title}
                    />
                    <span>
          </span>
                </Link>
            </div>
        </div>
    );
};

export default ArticleListCard;
