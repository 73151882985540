import {combineReducers} from 'redux';
import {articleApi} from "../api/article";
import {categoryApi} from "../api/category";
import {commentApi} from "../api/comment";
import {tagApi} from "../api/tag";
import {snackbarSlice} from "./snackbarSlice";
import {authSlice} from "./AuthSlice";
import {authApi} from "../api/auth";

const rootReducer = combineReducers({
    snackbar: snackbarSlice.reducer,
    auth: authSlice.reducer,

    // api
    // article
    [articleApi.reducerPath]: articleApi.reducer,

    // category
    [categoryApi.reducerPath]: categoryApi.reducer,

    // comment
    [commentApi.reducerPath]: commentApi.reducer,

    // tag
    [tagApi.reducerPath]: tagApi.reducer,

    //user
    [authApi.reducerPath]: authApi.reducer,
});

export default rootReducer;