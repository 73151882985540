import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import React, {FC} from "react";
import {useGetCategoryListQuery} from "store/api/category";
import CategoryCard from "../CategoryCard/CategoryCard";

export interface CustomWidgetCategoryProps {
    className?: string;
}

const CustomWidgetCategory: FC<CustomWidgetCategoryProps> = ({
                                                         className = "bg-neutral-100 dark:bg-neutral-800",
                                                     }) => {

    const { data} = useGetCategoryListQuery();
    return (
        <div
            className={`nc-WidgetCategories rounded-3xl  overflow-hidden ${className}`}
        >
            <WidgetHeading1
                title="✨ Trending topic"
                viewAll={{ label: "View all", href: "/#" }}
            />
            <div className="flow-root">
                <div className="flex flex-col divide-y divide-neutral-200 dark:divide-neutral-700">
                    {data?.data.map((category) => (
                        <CategoryCard
                            className="p-4 xl:p-5 hover:bg-neutral-200 dark:hover:bg-neutral-700"
                            key={category.id}
                            category={category}
                            size="normal"
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomWidgetCategory;
