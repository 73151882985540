import React, {useEffect} from "react";
import NcImage from "components/NcImage/NcImage";
import SingleHeader from "app/(singles)/SingleHeader";
import Layout from "../layout";
import {useQuery} from "../../../../utils/queryUtils";
import {
  debouncedUpdateViewCount,
  useGetArticleDetailQuery,
  useUpdateViewCountMutation
} from "../../../../store/api/article";
import Page404 from "../../../not-found";
import {Helmet} from "react-helmet";

const PageSingle = () => {

  const articleId = useQuery('aid');

  const [updateViewCount] = useUpdateViewCountMutation();
  const { data , isLoading} = useGetArticleDetailQuery(articleId ?? '');

  useEffect(() => {
    if (articleId) {
      debouncedUpdateViewCount(updateViewCount, articleId);
    }
  }, [articleId, updateViewCount]);

  if (data?.code !== 200 && !isLoading) return <Page404 />;

  return (
      <React.Fragment>
        <Helmet>
          <title>{data?.data.title}</title>
          <meta name="description" content={data?.data.summary} />
        </Helmet>


        <Layout>
          <div className={`nc-PageSingle pt-8 lg:pt-16`}>
            <header className="container rounded-xl">
              <div className="max-w-screen-md mx-auto">
                <SingleHeader article={data?.data}/>
              </div>
            </header>

            {/* FEATURED IMAGE */}
            <NcImage
              alt="single"
              containerClassName="container my-10 sm:my-12"
              className="w-full rounded-xl"
              src={data?.data.thumbnail}
              width={1260}
              height={750}
              sizes="(max-width: 1024px) 100vw, 1280px"
            />
          </div>
        </Layout>

      </React.Fragment>
  );
};

export default PageSingle;
