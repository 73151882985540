import ButtonPrimary from "components/Button/ButtonPrimary";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import React, {useEffect, useState} from "react";
import Layout from "../../layout";
import LayoutDashboard from "../layout";
import Avatar from "../../../../components/Avatar/Avatar";
import {useUpdateUserInfoApiMutation, useUploadImgMutation, useUserInfoQuery} from "../../../../store/api/auth";
import {useAppDispatch, useAppSelector} from "../../../../store";
import {updateUserInfo} from "../../../../store/reducers/AuthSlice";
import {showSnackbar} from "../../../../store/reducers/snackbarSlice";
import Page404 from "../../../not-found";

const DashboardEditProfile = () => {
  const dispatch = useAppDispatch();
  const [uploadImg] = useUploadImgMutation();
  const [updateUserInfoApi] = useUpdateUserInfoApiMutation();

  const token = useAppSelector((state) => state.auth.token) ?? '';
  const [uploadUrl, setUploadUrl] = useState('');
  const [nickname, setNickname] = useState('');

  const { data, isLoading } = useUserInfoQuery({ token });

  useEffect(() => {
    if (data?.code === 200) {
      setUploadUrl(data.data.avatar);
      setNickname(data.data.nickName);
      dispatch(updateUserInfo(data.data));
    }
  }, [data, dispatch]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const selectedFile = e.target.files[0];

      // Upload the file immediately after selection
      const formData = new FormData();
      formData.append('img', selectedFile);

      const response = await uploadImg({ formData, token }).unwrap();
      setUploadUrl(response.data);
    }
  };

  const handleNicknameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(e.target.value);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      await updateUserInfoApi({ avatar: uploadUrl, nickName: nickname, token });
      dispatch(showSnackbar({ message: 'Profile updated successfully!', severity: 'success' }));
    } catch (error) {
      // console.error('Error updating user info:', error);
    }
  };

  if (data?.code !== 200 && !isLoading) return <Page404 />;

  return (
    <Layout>
      <LayoutDashboard>
        <div className="rounded-xl md:border md:border-neutral-100 dark:border-neutral-800 md:p-6">
          <form className="grid md:grid-cols-2 gap-6" action="#" method="post" onSubmit={handleSubmit}>
            <label className="block">
              <div className="flex items-center">
                <Avatar imgUrl={uploadUrl} userName={data?.data.nickName} sizeClass="w-12 h-12"/>
                <div className="flex-1 ml-2">
                  <Label className="ml-3">Avatar</Label>
                  <Input type="file" className="mt-1 w-full" onChange={handleFileChange}/>
                </div>
              </div>
            </label>
            <label className="block">
              <Label>Nickname</Label>
              <Input
                  type="text"
                  className="mt-1 w-full"
                  value={nickname}
                  onChange={handleNicknameChange}
              />
            </label>
            {/*<label className="block">*/}
            {/*  <Label>Current password</Label>*/}
            {/*  <Input placeholder="***" type="password" className="mt-1" />*/}
            {/*</label>*/}
            {/*<label className="block">*/}
            {/*  <Label>New password</Label>*/}
            {/*  <Input type="password" className="mt-1" />*/}
            {/*</label>*/}
            {/*<label className="block md:col-span-2">*/}
            {/*  <Label> Email address</Label>*/}
            {/*  <Input*/}
            {/*    type="text"*/}
            {/*    placeholder="example@example.com"*/}
            {/*    className="mt-1"*/}
            {/*  />*/}
            {/*</label>*/}
            <ButtonPrimary className="md:col-span-2" type="submit">
              Update profile
            </ButtonPrimary>
          </form>
        </div>
      </LayoutDashboard>
    </Layout>
  );
};

export default DashboardEditProfile;
