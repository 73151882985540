import React, {FC} from "react";
import NcImage from "components/NcImage/NcImage";
import {HotArticleVo} from "data/types";
import Link from "components/Link";
import Image from "../Image";
import EyeIcon from "images/eye-svgrepo-com.svg";
import convertNumbThousand from "../../utils/convertNumbThousand";
import CategoryBadgeDisplay from "../CategoryBadgeDisplay/CategoryBadgeList";
import {DateUtils} from "utils/DateUtils";
import {DETAIL_ARTICLE_BASE_URL} from "contains/constants";


export interface HotArticleCardProps {
    className?: string;
    hotArticle: HotArticleVo;
    hoverClass?: string;
    ratio?: string;
}

const HotArticleCard: FC<HotArticleCardProps> = ({
     className = "h-full",
     ratio = "aspect-w-5 aspect-h-5",
     hoverClass = "",
     hotArticle
 }) => {
    const {id, title, viewCount, categoryName, thumbnail, createTime} = hotArticle;
    const viewCountNumber = parseInt(viewCount, 10);
    const formattedDate = DateUtils.formatDate(createTime);
    const href: string = `${DETAIL_ARTICLE_BASE_URL}${id}`;

    return (
        <div
            className={`nc-Card14 relative flex flex-col group rounded-3xl overflow-hidden ${hoverClass} ${className}`}
        >
            <Link href={href} className={`flex items-start relative w-full ${ratio}`}>
                <NcImage
                    alt="post"
                    containerClassName="absolute inset-0 overflow-hidden"
                    fill
                    className="object-cover w-full h-full rounded-3xl"
                    src={thumbnail}
                />

                <span className="absolute inset-0 bg-black bg-opacity-40">
        </span>
            </Link>

            <div className="absolute top-4 inset-x-4 sm:top-5 sm:inset-x-5">
                <CategoryBadgeDisplay itemClass="px-3 py-[6px]" categoryName={hotArticle.categoryName}  categoryId={hotArticle.categoryId}/>
            </div>

            <div className="dark absolute bottom-4 inset-x-4 sm:bottom-5 sm:inset-x-5 flex flex-col flex-grow">
                <h2 className="block text-base font-semibold text-white">
                    <Link href={href} className="line-clamp-2" title={title}>
                        {title}
                    </Link>
                </h2>

                <div className="p-2 sm:p-2.5 mt-4 sm:mt-5 bg-white bg-opacity-20 backdrop-filter backdrop-blur-lg rounded-full flex items-center text-neutral-50 text-xs sm:text-sm font-medium">
                    <div
                        className="relative flex items-center space-x-2"
                    >
                        {/*<Avatar*/}
                        {/*    radius="rounded-full"*/}
                        {/*    containerClassName="ring-2 ring-white"*/}
                        {/*    sizeClass="h-7 w-7 text-sm"*/}
                        {/*    imgUrl="Ncmaz/src/images/view-simple.png"*/}
                        {/*    userName={author.displayName}*/}
                        {/*/>*/}
                        <Image
                            className="flex-shrink-0"
                            src={EyeIcon}
                            alt="Viewed"
                        />
                        <span className="block text-white truncate">
                            {convertNumbThousand(viewCountNumber)} Viewed
                        </span>
                    </div>
                    <>
                        <span className=" mx-[6px]">·</span>
                        <span className=" font-normal truncate">{formattedDate}</span>
                    </>
                </div>
            </div>
        </div>
    );
};

export default HotArticleCard;
