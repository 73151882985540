import React, {FC} from "react";
import {CategoryVo} from "data/types";
import {useNavigate, useSearchParams} from "react-router-dom";

export interface CategoryCardProps {
    className?: string;
    category: CategoryVo;
    size?: "large" | "normal";
}

const CategoryCard: FC<CategoryCardProps> = ({
                                                   className = "",
                                                   size = "normal",
                                                 category,
                                               }) => {
    const { articleCount, name } = category;
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    const handleClick = () => {
        const params = new URLSearchParams(searchParams);
        params.set('cid', category.id);
        navigate({
            pathname: '/',
            search: params.toString(),
        });
    };
    return (
        <div
            onClick={handleClick}
            className={`nc-CardCategory1 flex items-center cursor-pointer ${className}`}
        >
            {/*<NcImage*/}
            {/*    alt=""*/}
            {/*    containerClassName={`relative flex-shrink-0 ${*/}
            {/*        size === "large" ? "w-20 h-20" : "w-12 h-12"*/}
            {/*    } rounded-lg mr-4 overflow-hidden`}*/}
            {/*    src={thumbnail || ""}*/}
            {/*    fill*/}
            {/*    className="object-cover"*/}
            {/*    sizes="80px"*/}
            {/*/>*/}
            <div>
                <h2
                    className={`${
                        size === "large" ? "text-lg" : "text-base"
                    } nc-card-title text-neutral-900 dark:text-neutral-100 text-sm sm:text-base font-medium sm:font-semibold`}
                >
                    {name}
                </h2>
                <span
                    className={`${
                        size === "large" ? "text-sm" : "text-xs"
                    } block mt-[2px] text-neutral-500 dark:text-neutral-400`}
                >
          {articleCount} Articles
        </span>
            </div>
        </div>
    );
};

export default CategoryCard;