import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {UserInfo} from "data/types";

interface AuthState {
    token: string | null;
    userInfo: UserInfo | null;
    isAuthenticated: boolean;
}

const initialState: AuthState = {
    token: localStorage.getItem('token') || null,
    userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo') as string) : null,
    isAuthenticated: !!localStorage.getItem('token'),
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        loginSuccess: (state, action: PayloadAction<{ token: string; userInfo: UserInfo }>) => {
            state.token = action.payload.token;
            state.userInfo = action.payload.userInfo;
            state.isAuthenticated = true;
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo));
        },
        logout: (state) => {
            state.token = null;
            state.userInfo = null;
            state.isAuthenticated = false;
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
        },
        updateUserInfo: (state, action: PayloadAction<UserInfo>) => {
            state.userInfo = action.payload;
            if (state.userInfo) {
                localStorage.setItem('userInfo', JSON.stringify(state.userInfo));
            }
        },
    },
});

export const { loginSuccess, logout, updateUserInfo } = authSlice.actions;

export default authSlice.reducer;
