import React, {FC} from "react";
import WidgetHeading1 from "components/WidgetHeading1/WidgetHeading1";
import {useGetTagListQuery} from "../../store/api/tag";
import CustomTag from "../blog/CustomTags/CustomTags";

// const tagsDemo = DEMO_TAGS.filter((_, i) => i < 9);

export interface WidgetTagsProps {
  className?: string;
  // tags?: TagVo[];
}

const WidgetTags: FC<WidgetTagsProps> = ({
  className = "bg-neutral-100 dark:bg-neutral-800",
  // tags,
}) => {
    const {data} = useGetTagListQuery();
  return (
    <div className={`nc-WidgetTags rounded-3xl overflow-hidden ${className}`}>
      <WidgetHeading1
        title="💡 More tags"
        viewAll={{ label: "View all", href: "/#" }}
      />
      <div className="flex flex-wrap p-4 xl:p-5">
        {data?.data.map((tag) => (
          <CustomTag className="mr-2 mb-2" key={tag.id} tag={tag} />
        ))}
      </div>
    </div>
  );
};

export default WidgetTags;
