interface SEOProps {
    page: string;
    description: string;
    keywords: string[];
}

const SEO: SEOProps[] = [
    {
        page: "home",
        description:
        "Welcome to Shiguo Feng's blog, where you'll find insightful articles on Java, Python, software development practices, and the latest trends in backend technologies. Explore tutorials, best practices, and in-depth guides to elevate your coding skills.",
        keywords: ["Shiguo", "Shiguo Blog", "Shiguo Feng", "java blog"],
    },
];

export default SEO;