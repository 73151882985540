import {showSnackbar} from "../reducers/snackbarSlice";
import {AnyAction, Middleware} from '@reduxjs/toolkit';
import {ThunkDispatch} from 'redux-thunk';
import {ResponseResult} from "../../data/types";
import {logout} from "../reducers/AuthSlice";

const apiMiddleware: Middleware<{}, any, ThunkDispatch<any, any, AnyAction>> =
    ({ dispatch }) =>
        next =>
            action => {
                // @ts-ignore
                if (action.type.endsWith('/rejected')) {
                    const { error } = action as AnyAction;
                    dispatch(showSnackbar({ message: error.data?.msg || error.error || 'An error occurred', severity: 'error' }));
                } else { // @ts-ignore
                    if (action.type.endsWith('/fulfilled')) {
                        const { payload } = action as AnyAction;
                        const response = payload as ResponseResult<any>;

                        if (response.code !== 200) {
                            dispatch(showSnackbar({ message: response.msg || 'An error occurred', severity: 'error' }));

                            // Check for 401 or 402 status codes
                            if (response.code === 401 || response.code === 402) {
                                dispatch(logout());
                                dispatch(showSnackbar({ message: 'Session expired. Please log in again.', severity: 'warning' }));
                            }
                        }
                    }
                }
                return next(action);
            };

export default apiMiddleware;