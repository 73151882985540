import React, {FC, useState} from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import CommentCard from "components/CommentCard/CommentCard";
import {useQuery} from "../../utils/queryUtils";
import {useGetCommentCountQuery, useGetCommentListQuery} from "../../store/api/comment";

export interface SingleCommentListsProps {}

const SingleCommentLists: FC<SingleCommentListsProps> = () => {

    const [pageSize, setPageSize] = useState(10);
    const articleId = parseInt(useQuery('aid') || '0', 10);
    const { data: countData } = useGetCommentCountQuery({ articleId });
    const { data: commentData, refetch } = useGetCommentListQuery({ articleId, pageNum: 1, pageSize });

    const handleViewAllComments = () => {
        setPageSize(parseInt(countData?.data ?? '0', 10) || 10);
        refetch();
    };

    return (
    <ul className="nc-SingleCommentLists space-y-5">
        {commentData?.data.rows.map(comment => (
            <CommentCard key={comment.id} comment={comment} />
        ))}
      {/*<CommentCard />*/}
      {/*<CommentCard />*/}
      {/*<CommentCard />*/}

        {/* View full comments button */}
        {commentData && countData && commentData.data.rows.length < parseInt(countData.data, 10) && (
            <ButtonPrimary className="dark:bg-primary-700 w-full" onClick={handleViewAllComments}>
                View full comments (+{parseInt(countData.data, 10) - commentData.data.rows.length} comments)
            </ButtonPrimary>
        )}

    </ul>
  );
};

export default SingleCommentLists;
