import {TagVo} from "data/types";
import Link from "components/Link";
import React, {FC} from "react";
import {useLocation} from "react-router-dom";

export interface CustomTagProps {
    className?: string;
    tag: TagVo;
    hideCount?: boolean;
}

const CustomTag: FC<CustomTagProps> = ({ className = "", tag, hideCount = false }) => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    params.set('tid', tag.id);
    const href = `/?${params.toString()}`;

    return (
        <Link
            className={`nc-Tag inline-block bg-white hover:bg-neutral-50 text-sm text-neutral-600 dark:text-neutral-300 py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-neutral-900 ${className}`}
            href={href}
        >
            {`${tag.name}`}
            {!hideCount && (
                <span className="text-xs font-normal"> ({tag.articleCount})</span>
            )}
        </Link>
    );
};

export default CustomTag;
