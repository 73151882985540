import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {BACKEND_BASE_URL} from "../../contains/constants";
import {CategoryVo, ResponseResult} from "../../data/types";

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: fetchBaseQuery({ baseUrl: BACKEND_BASE_URL }),

    endpoints: (build) => ({
        getCategoryList: build.query<ResponseResult<CategoryVo[]>, void>({
            query: () => 'category/getCategoryList',
        }),
    }),
});

export const { useGetCategoryListQuery } = categoryApi;