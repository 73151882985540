import React, {FC} from "react";
import SingleTitle from "./SingleTitle";
import PostMeta2 from "components/PostMeta2/PostMeta2";
import SingleMetaAction2 from "./SingleMetaAction2";
import {ArticleDetailVo} from "../../data/types";
import CategoryBadgeDisplay from "../../components/CategoryBadgeDisplay/CategoryBadgeList";
import {DateUtils} from "../../utils/DateUtils";

export interface SingleHeaderProps {
  hiddenDesc?: boolean;
  titleMainClass?: string;
  className?: string;
  article?: ArticleDetailVo;
}

const SingleHeader: FC<SingleHeaderProps> = ({
  titleMainClass,
  hiddenDesc = false,
  className = "",
  article,
}) => {
  const date = article?.createTime ? DateUtils.formatDate(article.createTime) : '';
  const viewCount = parseInt(article?.viewCount ?? '0', 10)
  return (
    <>
      <div className={`nc-SingleHeader ${className}`}>
        <div className="space-y-5">
          {article && (
              <>
                <CategoryBadgeDisplay
                    itemClass="!px-3"
                    categoryId={article.categoryId}
                    categoryName={article.categoryName}
                />

                <SingleTitle
                  mainClass={titleMainClass}
                  title={article.title}
                />
            </>
          )}
          {!hiddenDesc && (
            <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
              {article?.summary ?? ''}
            </span>
          )}
          <div className="w-full border-b border-neutral-200 dark:border-neutral-700"></div>
          <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
            <PostMeta2
              size="large"
              className="leading-none flex-shrink-0"
              hiddenCategories
              avatarRounded="rounded-full shadow-inner"
              viewCount={viewCount}
              date={date}
            />
            <SingleMetaAction2 />
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleHeader;
