import React from 'react';

import {hideSnackbar} from "../../store/reducers/snackbarSlice";
import {useAppDispatch, useAppSelector} from "../../store";

const Snackbar = () => {
    const dispatch = useAppDispatch();
    const { open, message, severity } = useAppSelector((state) => state.snackbar);

    if (!open) return null;

    let severityClass = 'bg-blue-500';
    if (severity === 'success') severityClass = 'bg-green-500';
    else if (severity === 'warning') severityClass = 'bg-yellow-500';
    else if (severity === 'error') severityClass = 'bg-red-500';

    return (
        <div className={`fixed top-4 left-1/2 transform -translate-x-1/2 p-4 rounded-md shadow-lg text-white ${severityClass} z-50`}>
            {message}
            <button onClick={() => dispatch(hideSnackbar())} className="ml-4 text-white">
                &times;
            </button>
        </div>
    );
};

export default Snackbar;
