import React, {FC} from "react";
import {useSearchParams} from "react-router-dom";

export interface PaginationProps {
  className?: string;
  totalPages?: number;
  currentPage?: number;
}

const Pagination: FC<PaginationProps> = ({
                                           className = "",
                                           totalPages = 0,
                                           currentPage
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handlePageChange = (page: number) => {
      const params = new URLSearchParams(searchParams.toString());
      params.set('page', page.toString());
      setSearchParams(params);
  };

  const renderItem = (page: number) => {
    const isActive = page === currentPage;
    return isActive ? (
        <span
            key={page}
            className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-primary-6000 text-white`}
        >
        {page}
      </span>
    ) : (
        <div
            key={page}
            className={`inline-flex w-11 h-11 items-center justify-center rounded-full bg-white hover:bg-neutral-100 border border-neutral-200 text-neutral-6000 cursor-pointer`}
            onClick={() => handlePageChange(page)}
        >
          {page}
        </div>
    );
  };

  return (
    <nav
      className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}
    >
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(renderItem)}
    </nav>
  );
};

export default Pagination;
