import React, {FC} from "react";
import Badge from "components/Badge/Badge";
import {BADGE_COLORS} from "../../contains/constants";
import {useLocation} from "react-router-dom";

export interface CategoryBadgeDisplayProps {
  className?: string;
  itemClass?: string;
  categoryName: string;
  categoryId: string;
}

const CategoryBadgeDisplay: FC<CategoryBadgeDisplayProps> = ({
  className = "flex flex-wrap space-x-2",
  itemClass,
  categoryName,
  categoryId,
}) => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  params.set('cid', categoryId);
  const href = `/?${params.toString()}`;

  // Determine the color based on categoryId
  const colorIndex = parseInt(categoryId, 10) % BADGE_COLORS.length;
  const badgeColor = BADGE_COLORS[colorIndex];

  return (
    <div
      className={`nc-CategoryBadgeList ${className}`}
      data-nc-id="CategoryBadgeList"
    >
        <Badge
          className={itemClass}
          name={categoryName}
          href={href}
          color={badgeColor as any}
        />
    </div>
  );
};

export default CategoryBadgeDisplay;
