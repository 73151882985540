import React, {FC} from "react";
import Image from "../../Image";
import EyeIcon from "../../../images/eye-svgrepo-com.svg";
import convertNumbThousand from "../../../utils/convertNumbThousand";
import {DateUtils} from "../../../utils/DateUtils";

export interface ArticleListDateViewedProps {
    className?: string;
    viewCount: string;
    createDate: string;
}

const ArticleListDateViewed: FC<ArticleListDateViewedProps> = ({
    className = "leading-none text-xs",
    viewCount,
    createDate,
}) => {
    const viewCountNumber = parseInt(viewCount,10);
    const formattedDate = DateUtils.formatDate(createDate);

    return (
        <div
            className={`nc-PostCardMeta inline-flex items-center flex-wrap text-neutral-800 dark:text-neutral-200 ${className}`}
        >
            <Image
                className="flex-shrink-0 mr-1"
                src={EyeIcon}
                alt="Viewed"
            />
            <span
                className="block text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white font-medium">
                {convertNumbThousand(viewCountNumber)} Viewed
            </span>
            <>
        <span className="text-neutral-500 dark:text-neutral-400 mx-[6px] font-medium">
          ·
        </span>
        <span className="text-neutral-500 dark:text-neutral-400 font-normal">
          {formattedDate}
        </span>
            </>
        </div>
    );
};

export default ArticleListDateViewed;
